import classNames from 'classnames';
import merge from 'lodash/merge';
import React, { useMemo } from 'react';

import { getLink } from '../urls';

import { DELAY_OFFSET } from './animations/config';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from './animations/group-animation';
import Image from './image';
import Link from './link';

const ANIMATION_OFFSET = 125; // in milliseconds
const IMAGE_STYLE = {
  objectFit: 'cover',
  objectPosition: '50% 50%',
};

// This is a hack! The returned sources from the gatsby-image query helper
// "gatsbyImageData()" is not returning valid contentful urls when expecting to
// crop to a certain size using the options `aspectRatio` and `width` or `height`.
// A `&fit=fill` param is required to receive the image in the requested aspect
// ratio, otherwise the image stays in the aspect ratio of the original image.
function imageFitParamHack(props) {
  if (!props) {
    return null;
  }

  function appendFitParam(src) {
    if (!src) {
      return src;
    }

    return `${src}&fit=fill`;
  }

  function appendFitParamToSrcSet(srcSet) {
    if (!srcSet) {
      return srcSet;
    }

    return srcSet
      .split(',')
      .map((entry) => {
        const [src, size] = entry.split(' ');
        return [appendFitParam(src), size].join(' ');
      })
      .join(', ');
  }

  const { fallback, sources } = props?.image?.gatsbyImageData?.images ?? {};
  return merge({}, props, {
    image: {
      gatsbyImageData: {
        images: {
          fallback: {
            src: appendFitParam(fallback?.src),
            srcSet: appendFitParamToSrcSet(fallback?.srcSet),
          },
          sources: sources?.map((source) => {
            return {
              ...source,
              srcSet: appendFitParamToSrcSet(source?.srcSet),
            };
          }),
        },
      },
    },
  });
}

const Banner = ({ reading, type }) => {
  const props = useMemo(() => {
    switch (true) {
      case !!reading.featuredProjectBanner?.file:
        return {
          image: reading.featuredProjectBanner,
          alt: reading.featuredProjectBanner.title,
        };
      case !!reading.tile_image_tall?.file:
        return {
          image: reading.tile_image_tall,
          alt: reading.tile_image_tall.title,
        };
      case !!reading.tile_image_regular?.file:
        return {
          image: reading.tile_image_regular,
          alt: reading.tile_image_regular.title,
        };
      case !!reading.main_image?.file:
        return {
          image: reading.main_image,
          alt: reading.main_image.title,
        };
      default:
        return null;
    }
  }, [reading]);

  const fitment = useMemo(() => {
    switch (type) {
      case 'overlay':
        return imageFitParamHack(props);
      default:
        return props;
    }
  }, [props]);

  if (!fitment) {
    return null;
  }

  return (
    <div className="article-teaser__image">
      <Image {...fitment} imgStyle={IMAGE_STYLE} />
    </div>
  );
};

const Overlay = ({ noLabel, reading, withSubline }) => (
  <div className="article-teaser__text">
    {!noLabel && reading.post_category && (
      <span className="article-teaser__category">{reading.post_category}</span>
    )}
    {reading.title && (
      <span className="article-teaser__title" itemProp="name">
        {reading.title}
      </span>
    )}
    {reading.teaser && withSubline && (
      <p className="article-teaser__subline" itemProp="description">
        {reading.teaser.teaser}
      </p>
    )}
  </div>
);

const SmallTeaser = ({ noLabel, reading, withSubline }) => (
  <>
    {!noLabel && reading.post_category && (
      <span className="article-teaser__category">{reading.post_category}</span>
    )}

    {reading.title && (
      <span className="article-teaser__title" itemProp="name">
        {reading.title}
      </span>
    )}

    {reading.teaser && withSubline && (
      <p className="article-teaser__subline" itemProp="description">
        {reading.teaser.teaser}
      </p>
    )}
  </>
);

const RegularTeaser = ({ reading, withSubline }) => (
  <>
    {reading.title && (
      <span className="article-teaser__title" itemProp="name">
        {reading.title}
      </span>
    )}
    {reading.teaser && withSubline && (
      <p className="article-teaser__subline" itemProp="description">
        {reading.teaser.teaser}
      </p>
    )}
  </>
);

const RelatedReading = ({ noLabel, reading, delay }) => (
  <>
    <GroupedTextAnimation as="span" className="article-teaser__category" delay={delay}>
      {!noLabel && reading.post_category ? reading.post_category : reading.title}
    </GroupedTextAnimation>

    {reading.title && reading.post_category ? (
      <GroupedTextAnimation
        as="span"
        className="article-teaser__title"
        itemProp="name"
        delay={delay}
      >
        {reading.title}
      </GroupedTextAnimation>
    ) : (
      <GroupedTextAnimation
        as="p"
        className="article-teaser__title"
        itemProp="description"
        delay={delay}
      >
        {reading.subtitle}
      </GroupedTextAnimation>
    )}
  </>
);

const ArticleTeaser = (props) => {
  const { reading, tall, withSubline, noLabel, type, index = 0 } = props;

  const tileImageClasses = classNames({
    'article-teaser__image-wrapper': true,
    'article-teaser__image-wrapper--tall': tall,
  });

  const delay = index * ANIMATION_OFFSET;

  return (
    <div
      className={`related-reading-tile article-teaser-list__item ${
        type ? `related-reading-tile article-teaser-list__item--${type}` : ''
      } ${tall ? 'related-reading-tile article-teaser-list__item--tall' : ''}`}
    >
      <GroupAnimation as="article" className="article-teaser">
        <Link link={getLink(reading)} className="article-teaser__link">
          <GroupedBlockAnimation className={tileImageClasses} delay={delay}>
            <Banner reading={reading} type={type} />

            {/* If overlay, place content inside of image-wrapper */}
            {type === 'overlay' && (
              <Overlay noLabel={noLabel} reading={reading} withSubline={withSubline} />
            )}
          </GroupedBlockAnimation>

          <div className="article-teaser__text">
            {/* Teaser with title and teaser text */}
            {type === 'regular' && <RegularTeaser reading={reading} withSubline={withSubline} />}

            {/* Teaser with small image and text overlay */}
            {type === 'small' && (
              <SmallTeaser noLabel={noLabel} reading={reading} withSubline={withSubline} />
            )}

            {/* Teaser with category name and teaser text */}
            {type !== 'overlay' && type !== 'small' && type !== 'regular' && (
              <RelatedReading
                noLabel={noLabel}
                reading={reading}
                delay={delay + DELAY_OFFSET * 2}
              />
            )}
          </div>
        </Link>
      </GroupAnimation>
    </div>
  );
};

export default ArticleTeaser;
